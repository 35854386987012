import { create } from './axios';
import BaseApi from './base-api';

class Contextual extends BaseApi {
  configurations() {
    return this.get('/configurations');
  }

  constants(params = {}) {
    return this.get('/shared_constants', params)
  }
}

const axios = create('/api/');

export default new Contextual(axios);
