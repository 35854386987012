// TODO: the Services below is not accurate, as well as the checks under getComponentName.
// we need to have a more accurate list of support as well as a more streamlined way to map
// the routes to the Services we support

// Derived from https://github.com/UrbanCompass/glide-devapp/blob/development/webapp/libs/monitoring/traces.py
const Services = {
  ADMIN: 'ADMIN',
  BUYERS: 'BUYERS',
  DISCLOSURES: 'DISCLOSURES',
  DOCUMENTS: 'DOCUMENTS',
  ESIGN: 'ESIGN',
  GROWTH: 'GROWTH',
  HELP_CENTER: 'HELP_CENTER',
  OFFERS: 'OFFERS',
  ORGANIZATIONS: 'ORGANIZATIONS',
  TRANSACTION: 'TRANSACTION',
  UNKNOWN: 'UNKNOWN',
  USERACCOUNT: 'USERACCOUNT',
};

// simplified respresentation of routedata
interface RouteData {
  name: string;
  params: {
    path: string;
  };
}

interface ServiceMapping {
  serviceName: string;
  matchingPaths: string[];
}

const serviceMap: ServiceMapping[] = [
  {
    serviceName: Services.ADMIN,
    matchingPaths: [],
  },
  {
    serviceName: Services.DISCLOSURES,
    matchingPaths: [
      'workflows',
      'packets',
      'disclosure',
      'disclosurePackage',
      'disclosures',
      'packets',
      'sflow',
    ],
  },
  {
    serviceName: Services.DOCUMENTS,
    matchingPaths: ['documents', 'transactionTemplates', 'clauses'],
  },
  {
    serviceName: Services.ESIGN,
    matchingPaths: ['esign', 'signature-requests', 'docusignViewEnvelope'],
  },
  {
    serviceName: Services.USERACCOUNT,
    matchingPaths: ['account', 'upgrade', 'billing', 'invite'],
  },
  {
    serviceName: Services.OFFERS,
    matchingPaths: ['offers', 'properties', 'parties'],
  },
  {
    serviceName: Services.TRANSACTION,
    matchingPaths: ['transactions', 'archived'],
  },
];

// Services without any clear owner:
// [
//   'advertising-hub',
//   'comments',
//   'checklists',
//   'compliance',
//   'items',
//   'timeline',
//   'details',
//   'activity',
//   'chores',
//   'vendors',
//   'zipformViewTransaction',
//   'orgs',
//   'compass',
//   'advertising-hub-flow',
//   'route',
// ]

function belongsToTeam(tokens: string[], pathNames: string[]) {
  return pathNames.some((path) => tokens.includes(path));
}

// Currently the list of route name can be found here:
// https://github.com/UrbanCompass/glide-devapp/blob/development/jsapp/src/entries/app/routes.js#L208
const getComponentName = (routeData: RouteData) => {
  const usedRoute = routeData?.name ?? '';
  const tokens = usedRoute.split('.');
  // TODO: Match based on flow using routeData.params.path.
  const matchedService = serviceMap.find((service) =>
    belongsToTeam(tokens, service.matchingPaths)
  );
  if (matchedService) {
    return matchedService.serviceName;
  }
  return Services.UNKNOWN;
};
export { getComponentName as default };
