class Logger {
  log(...args) {
    console.log(...args); // eslint-disable-line no-console
  }

  warn(...args) {
    console.warn(...args); // eslint-disable-line no-console
  }

  info(...args) {
    console.info(...args); // eslint-disable-line no-console
  }

  error(...args) {
    console.error(...args);

    // causes events like `Non-Error exception captured with keys: code, message, type`
    // to show up that are no use to anyone.
    const [err] = args;
    if (err && err instanceof Error) {
      window.DD_RUM?.addError(err);
    } else {
      const jsonString = JSON.stringify(args);
      if (jsonString) {
        window.DD_RUM?.addError(new Error(jsonString));
      }
    }
  }
}

export default new Logger();
