import colors from '../../stories/utils/colors';
import compassColors from '../../stories/utils/compass.colors';
import { getQueryParam } from './urls';

export const getColor = (key: string) => {
  const colorKey = key.indexOf('@') > -1 ? key : `@${key}`;
  const theme = getQueryParam('theme');
  const glideColor = colors[colorKey as keyof typeof colors];
  return theme === 'compass'
    ? compassColors[colorKey as keyof typeof compassColors] || glideColor
    : glideColor;
};
