import qs from 'qs';
import type { SnakeCasedProperties, SnakeCasedPropertiesDeep } from 'type-fest';
import type {
  LoginResponse,
  PeekResponse,
  RegistrationRequest,
  RegistrationResponse,
  WebappLoginResponse,
  ContactSuggestion,
  ApiAppRolePermissionsResponse,
} from 'src/types/proto/auth';
import type { InviteRequest } from 'src/types/proto/invites';
import type {
  FindUserContactsByEmailsRequest,
  FindUserContactsByEmailsResponse,
} from 'src/types/proto/services/account';
import type {
  ChangePasswordRequest,
  EmailAvailableResponse,
  GetInviteRequest,
  GetLoginTokenResponse,
  GetSessionDataResponse,
  IsUserPotentialResponse,
  LoginRequest,
  PeekRequest,
  RefreshWebappTokenResponse,
  ResendInviteEmailRequest,
  ResendPendingEmailRequest,
  ResetPasswordRequest,
  SendLoginLinkRequest,
  SendResetPasswordEmailRequest,
  WebappLoginByTokenRequest,
  GetNextUrlRequest,
  GetNextUrlResponse,
} from 'src/types/proto/services/auth_public_service';
import type {
  FetchContactSuggestionRequest,
  UpdatePartyContactSuggestionStatusRequest,
  UpdateProfileContactSuggestionStatusRequest,
} from 'src/types/proto/services/user_public_service';
import type { ApiRequestBody, ApiResponseData } from 'src/types/utils';
import BaseApi from '../base-api';
import { REFRESH_WEBAPP_TOKEN_URL, LOGOUT_URL } from '../urls';

export default class Auth extends BaseApi {
  fetchSession() {
    return this.get<GetSessionDataResponse>('/auth/session');
  }

  register(request: SnakeCasedProperties<RegistrationRequest>) {
    return this.post<RegistrationResponse>('/auth/register', request);
  }

  login(request: SnakeCasedProperties<LoginRequest>) {
    return this.post<LoginResponse>('/auth/login', request);
  }

  jwtLogin(request: SnakeCasedProperties<WebappLoginByTokenRequest>) {
    return this.post<WebappLoginResponse>('/auth/webapp_token_login', request);
  }

  logout() {
    return this.post<void>(LOGOUT_URL);
  }

  refreshWebappToken(request: unknown) {
    return this.post<RefreshWebappTokenResponse>(
      REFRESH_WEBAPP_TOKEN_URL,
      request
    );
  }

  isEmailAvailable(email: string) {
    return this.get<EmailAvailableResponse>(
      `/auth/email/available?email=${encodeURIComponent(email)}`
    );
  }

  peek(email: string) {
    return this.post<PeekResponse>('/auth/peek', {
      email,
    } as PeekRequest);
  }

  sendLoginLink(email: string, next?: string) {
    return this.post<boolean>('/auth/send_login_link', {
      email,
      next,
    } as SendLoginLinkRequest);
  }

  getInvite(uuid: string, params: Omit<GetInviteRequest, 'uuid'> = {}) {
    return this.get<InviteRequest>(
      `/auth/invites/${encodeURIComponent(uuid)}`,
      {
        params,
      }
    );
  }

  completeInvite(uuid: string) {
    return this.post<InviteRequest>(
      `/auth/invites/${encodeURIComponent(uuid)}/complete`
    );
  }

  cancelInvite(uuid: string) {
    return this.post<InviteRequest>(
      `/auth/invites/${encodeURIComponent(uuid)}/cancel`
    );
  }

  resendConfirmationEmail(email: string) {
    return this.post<boolean>('/auth/resend_confirmation_email', {
      email,
    });
  }

  fetchContactSuggestion(
    params: SnakeCasedProperties<FetchContactSuggestionRequest>
  ) {
    return this.get<ContactSuggestion>('/auth/fetch_contact_suggestion', {
      params,
    });
  }

  updateProfileContactSuggestionStatus(
    suggestionId: string,
    { status }: ApiRequestBody<UpdateProfileContactSuggestionStatusRequest>
  ) {
    return this.post<void>(
      `auth/update_profile_contact_suggestion_status/${suggestionId}`,
      {
        status,
      }
    );
  }

  updatePartyContactSuggestionStatus(
    suggestionId: string,
    { status }: ApiRequestBody<UpdatePartyContactSuggestionStatusRequest>
  ) {
    return this.post<void>(
      `auth/update_party_contact_suggestion_status/${suggestionId}`,
      {
        status,
      }
    );
  }

  sendResetPasswordEmail(email: string) {
    return this.post<void>('/auth/send_reset_password_email', {
      email,
    } as SendResetPasswordEmailRequest);
  }

  verifyResetToken(token: string) {
    return this.get<boolean>(
      `auth/verify_reset_token?token=${encodeURIComponent(token)}`
    );
  }

  resetPassword(token: string, password: string) {
    return this.post<boolean>('/auth/reset_password', {
      token,
      password,
    } as ResetPasswordRequest);
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.post<boolean>('/auth/change_password', {
      oldPassword,
      newPassword,
    } as ChangePasswordRequest);
  }

  findUserContactsByEmail(emails: string[]) {
    const query = qs.stringify(
      { em: emails } as FindUserContactsByEmailsRequest,
      { arrayFormat: 'repeat' }
    );
    return this.get<ApiResponseData<FindUserContactsByEmailsResponse>>(
      `/auth/find_user_contacts_by_emails?${query}`
    );
  }

  isUserPotential(email: string) {
    return this.get<IsUserPotentialResponse>(
      `/auth/is_user_potential?email=${encodeURIComponent(email)}`
    );
  }

  resendInviteEmail(email: string) {
    return this.post<void>('/auth/resend_invite_email', {
      email,
    } as ResendInviteEmailRequest);
  }

  resendPendingInvite(email: string) {
    return this.post<void>('/auth/resend_pending_invite', {
      email,
    } as ResendPendingEmailRequest);
  }

  updateAccessModeToAgent() {
    return this.post<void>('/auth/update_access_mode_to_agent');
  }

  getLoginToken() {
    // This only work if triggered from iOS app's webview, otherwise will get blocked by backend
    return this.post<GetLoginTokenResponse>('/auth/login_token');
  }

  getAppToken(appName: string, setCookie?: boolean) {
    const endpoint = `app_token${setCookie ? '_cookie' : ''}`;
    return this.get<string>(`/auth/${endpoint}?app=${appName}`);
  }

  getNextUrl(
    { view, mode }: Pick<GetNextUrlRequest, 'view' | 'mode'>,
    search: string
  ) {
    const restQuery = search.replace(/^\?/, '');
    return this.get<SnakeCasedPropertiesDeep<GetNextUrlResponse>>(
      `/auth/next_url?view=${view}&mode=${mode}${
        restQuery ? `&${restQuery}` : ''
      }`
    );
  }

  updateCompassAliasEmailConfirmed() {
    return this.post<boolean>('/auth/update_compass_alias_email_confirmed');
  }

  getAdminRolePermissions() {
    return this.get<ApiAppRolePermissionsResponse>(
      '/auth/admin_role_permissions'
    );
  }
}
