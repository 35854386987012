export default {
  '@app-secondary-color': '#7E45C7',
  '@app-tertiary-color': '#0B8FA6',
  '@app-primary-color': '#4F4BCB',
  '@app-accent-color-1': '#FA646E',
  '@app-accent-color-2': '#FDB83B',
  '@app-blue': '#1782E5',
  '@app-dark-bg-color': '#333333',
  '@app-dark-link-color': '#3ed8f2',
  '@app-gold': '#f2b512',
  '@light-background-color': '#F7F8F9',
  '@light-gray-color': '#f4f4f4',
  '@white-color': '#ffffff',
  '@dark-gray-color': '#999',
  '@app-gallery': '#EEE',
  '@app-alto': '#d9d9d9',
  '@gray-color': '#ccc',
  '@darker-border-gray-color': '#AEB3BA',
  '@darker-gray-color': '#2A3444',
  '@white-gray-color': '#F6F8FA',
  '@blue-gray-color': '#3F4857',
  '@light-blue-gray-color': '#656D79',
  '@light-purple-gray-color': '#858E99',
  '@green-color': '#4fa53a',
  '@accept-color': '#6AAE22',
  '@accept-highlight-color': '#98D15B',
  '@dark-green-color': '#05A87A',
  '@dark-red-color': '#b9191d',
  '@red-color': '#E53D41',
  '@error-color': '#a20817',
  '@error-background-color': '#FBEBEB',
  '@light-primary-color': '#4F4BCB',
  '@light-secondary-color': '#f6f1fb',
  '@light-tertiary-color': '#EAF5F6',
  '@navy-color': '#1c345d',
  '@black-blue-color': '#384458',
  '@pdf-blue-color': '#00208E',
  '@primary-color': '#4F4BCB',
  '@primary-1': '#4F4BCB',
  '@purple-blue-color': '#262260',
  '@purple-primary-color': '#4F4BCB',
  '@light-purple-color': '#7a73d9',
  '@dark-blue-color': '#403699',
  '@blue-color': '#4fb0ae',
  '@app-nav-color': '#2A3444',
  '@light-black-color': '#2d2f33',
  '@warning-text-color': '#FB9933',
  '@warning-background-color': '#fffaeb',
  '@warning-border-color': 'rgb(255, 243, 207)',
  '@app-nav-border-color-base': '#516483',
  '@dark-theme-border-color-base': '#516483',
  '@light-border-color-base': '#f1f1f1',
  '@dark-theme-row-highlight-color': 'rgba(255, 255, 255, 0.1)',
  '@dark-theme-button-highlight-color': 'rgba(255, 255, 255, 0.3)',
  '@title-primary-color': '#4F4BCB',
  '@title-color': '#4F4BCB',
  '@psd-yellow-color': '#FBBF00',
  '@psd-light-yellow-color': '#FEF6D9',
  '@light-yellow-color': '#FFE084',
  '@yellow-7': '#FECF60',
  '@yellow-8': '#FFEFC4',
  '@bg-yellow-color': '#FAE599',
  '@white-bg-color': '#EAF2F8',
  '@unlocked-color': '#EBEBF7',
  '@light-bg-yellow': '#FEF7E7',
  '@font-gray-color': '#aaaaaa',
  '@widget-text-color': '#234594',
  '@green-0': '#F0F7DE',
  '@green-7': '#84E9D8',
  '@green-9': '#00342B',
  '@green-5': '#13DBA2',
  '@green-6': ' #4fa53a',
  '@green-8': '#186d52',
  '@green-10': '#3A424C',
  '@gray-0': '#EEF9F8',
  '@gray-1': '#ededed',
  '@gray-8': '#7A7E82',
  '@gray-9': '#404B5B',
  '@gray-10': '#474747',
  '@gray-12': '#171717',
  '@blue-0': '#9AE5F7',
  '@blue-1': '#70AEFF',
  '@blue-2': '#48D3FF',
  '@blue-3': '#1890FF',
  '@blue-4': '#2292FA',
  '@blue-5': '#0064e5',
  '@blue-6': '#2850B2',
  '@black-6': '#112B46',
  '@black-8': '#001529',
  '@black-9': '#003C',
  '@black-10': '#000C',
  '@red-10': '#4E1C16',
  '@critical-active-color': '#590505',
  '@critical-hove-color': '#800707',
  '@secondary-text-color': '#596068',
  '@tip-text-color': '#828282',
  '@black-015-color': 'rgba(0, 0, 0, 0.15)',
  '@black-02-color': 'rgba(0, 0, 0, 0.2)',
  '@black-025-color': 'rgba(0, 0, 0, 0.25)',
  '@black-03-color': 'rgba(0, 0, 0, 0.3)',
  '@black-04-color': 'rgba(0, 0, 0, 0.4)',
  '@black-075-color': 'rgba(0, 0, 0, 0.75)',
  '@pspdfkit-designer-box-background-color': 'rgba(170, 170, 255, 0.15)',
  '@avatars-primary-color': '#DCDBFF',
  '@avatars-text-primary-color': '#181477',
  '@avatars-yellow-color': '#FFDF8E',
  '@avatars-text-yellow-color': '#755200',
  '@avatars-red-color': '#FFC9D0',
  '@avatars-text-red-color': '#480007',
  '@user-avatar-bg-color1': '#CCF9F2',
  '@user-avatar-bg-color2': '#F7CBBD',
  '@user-avatar-bg-color3': '#C9C7FF',
  '@user-avatar-bg-color4': '#FFDF8E',
  '@user-avatar-bg-color5': '#F6CDE8',
  '@user-avatar-bg-color6': '#C6F1FC',
};
