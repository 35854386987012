import { getQueryParam } from './urls';

export const COMPASS_SKIN = 'compass';

export function setCompassStyle(rememberThroughSession = true): boolean {
  // Setting body with .compass class is legacy approach but has to be supported still, until everything
  // is moved to use semantic less vars.
  document.body?.classList.add(COMPASS_SKIN);
  try {
    window.__styleName__ = 'compass';
    if (rememberThroughSession) {
      window.sessionStorage.setItem('__styleName__', 'compass');
    }
    // eslint-disable-next-line no-restricted-syntax
  } catch {
    // swallow error
  }

  return true;
}

export default function useCompassStyles(): boolean {
  let savedStyleName;
  try {
    savedStyleName =
      window.__styleName__ ?? window.sessionStorage.getItem('__styleName__');
  } catch (_error) {
    /**
     * In browser incognito mode (with Glide embedded as an iframe),
     * an error occurred that the 'sessionStorage' property from 'Window' can't be read.
     * So we return true to use compass style.
     */
    return setCompassStyle(true);
  }
  if (savedStyleName === 'compass') {
    return setCompassStyle(false);
  }
  if (getQueryParam('theme') === 'compass') {
    return setCompassStyle(true);
  }
  return false;
}
