import { COMPASS_URL } from 'src/constants';

const getLocalOrigin = () => {
  const arg = window.location.search.split('&').find(p => p.includes('embedded-origin'));
  return arg ? decodeURIComponent(arg.split('=')[1]) : window.location.origin
}

export const REFRESH_WEBAPP_TOKEN_URL = '/auth/refresh_webapp_token';
export const LOGOUT_URL = '/auth/logout';
export const API_V3_HOST = 
  process.env.NODE_ENV === 'development' ? getLocalOrigin() : COMPASS_URL;
export const API_V3_BASE_URL = `${API_V3_HOST}/api/v3`;
export const PARTY_MODAL_BASE_URL_OVERRIDE =
  process.env.NODE_ENV === 'development' ? window.location.origin : '';
