import { getCompassToken } from 'src/api/public/compass-app-token';
import { parseJwtPayload, getUnixNowTime } from './token';

let COMPASS_APP_TOKEN = '';
const TIME_BEFORE_EXPIRE = 300;

export const compassAppTokenGetter = () => COMPASS_APP_TOKEN;
export const compassAppTokenSetter = (value: string) => {
  COMPASS_APP_TOKEN = value;
};

export const getCompassAppToken = async () => {
  const exp = parseJwtPayload(COMPASS_APP_TOKEN)?.exp;
  const now = getUnixNowTime();

  if (!COMPASS_APP_TOKEN || !exp || now + TIME_BEFORE_EXPIRE >= exp) {
    const tokenRes = await getCompassToken();
    COMPASS_APP_TOKEN = tokenRes?.data?.token;
  }

  return COMPASS_APP_TOKEN;
};

export async function removeCompassAppToken() {
  COMPASS_APP_TOKEN = '';
}
